import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import Layout from '../components/layout'
import SEO from '../components/seo'
import SectionTop from '../components/section-top'
import Gallery from '../components/gallery'
import ContentWrapper from '../components/content-wrapper'

const PageIntro = styled(ContentWrapper)`
  margin-top: 10rem;
  margin-bottom: 5rem;

  @media (min-width: 48em) {
    margin-top: 20rem;
    margin-bottom: 12rem;
  }
`

const Text = styled.div`
  white-space: pre-line;
  max-width: 64.4rem;
  margin: 0 auto 10rem;
`

const Page = ({
  data: {
    contentfulPressRelease: { title, text, date, media },
  },
}) => {
  return (
    <Layout showPattern={true}>
      <SEO title={title} image={media?.file.url} />

      <article>
        <PageIntro>
          <PageIntro>
            <SectionTop title={title} as="h1" wide={true} />
          </PageIntro>
        </PageIntro>

        <ContentWrapper>
          <Text>
            <p>
              <b>{date}</b>
            </p>
            {renderRichText(text)}
          </Text>
        </ContentWrapper>

        {media && <Gallery images={[media]} removeTopPadding={true} />}
      </article>
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object,
}

export default Page

export const pressPageQuery = graphql`
  query PressReleasePage($id: String!) {
    contentfulPressRelease(id: { eq: $id }) {
      title
      text {
        raw
      }
      date
      media {
        file {
          url
        }
        fluid(quality: 50) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`
